<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <v-card class="shadow-off">
        <v-card-text>
          <v-row>
            <v-col class="border-right px-0">
              <v-card class="shadow-off">
                <v-card-text class="pt-0 d-flex">
                  <v-btn fab color="primary" elevation="0" small class="mr-4" to="/ia/engagement/add"><v-icon>mdi-plus</v-icon></v-btn>
                  <v-text-field label="Search" outlined hide-details dense></v-text-field>
                  <v-btn icon @click="refreshData()" class="ml-2"><v-icon>mdi-refresh</v-icon></v-btn>
                </v-card-text>

                <v-list dense v-if="listdata.length > 0">
                  <template v-for="(v, k) in listdata">
                    <v-list-item :key="k" link class="px-0 py-0 border-hover left" @click="$nova.gotoLink({path: '/ia/engagement/view/'+v._id})">


                      <v-list-item-content class="">
                        <v-list-item-title class="d-flex align-center px-4 py-0">
                          {{$nova.formatDate(v.from)}} - {{$nova.formatDate(v.to)}}
                          <v-spacer></v-spacer>
                          <v-btn icon @click.stop="selectedindex=k;modifydata={...v};errors={};addflag=false;editflag=true;"><v-icon>mdi-pencil</v-icon></v-btn>
                        </v-list-item-title>
                        <v-list-item-subtitle></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
                <div v-else>
                  <v-alert type="info" :value="true" text border="left">
                    Add new {{pageprops.name}} 
                  </v-alert>
                </div>

              </v-card>
            </v-col>
            <v-col v-if="Object.keys(modifydata).length > 0">
              <div v-if="addflag || editflag">
                <div class="pb-4 d-flex title align-center">
                  <span v-if="addflag" class="">Add New {{pageprops.name}}</span>
                  <span v-else-if="editflag" class="">Edit {{pageprops.name}} Details</span>
                  <v-spacer></v-spacer>
                </div>
                <lb-dropdown label="Client Name" v-model="modifydata['client']" :error="errors['client']" :items="selectdata.client" itemtext="name" itemvalue="_id" />
                <div class="d-flex">
                  <lb-date label="From Date" v-model="modifydata['from']" :error="errors['from']"/>
                  <span class="mr-4"></span>
                  <lb-date label="To Date" v-model="modifydata['to']" :error="errors['to']"/>
                </div>
                <div class="text-center pb-4">
                  <v-btn @click="addflag=false;editflag=false;modifydata={};errors={};" text class="mr-2">Cancel</v-btn>
                  <v-btn v-if="addflag" color="primary" @click="save()" elevation="0">Save</v-btn>
                  <v-btn v-else-if="editflag" color="primary" @click="update()" elevation="0">Update</v-btn>
                </div>
              </div>
              <div v-else>
                <div class="pb-4 d-flex title align-center">
                  <span class="">View {{pageprops.name}} Details</span>
                  <v-spacer></v-spacer>
                  <v-btn icon large v-if="listdata.length >= (selectedindex+1) && listdata.length > 0" @click="editflag=true;add=false"><v-icon>mdi-pencil</v-icon></v-btn>
                </div>

                <v-simple-table dense v-if="listdata.length >= (selectedindex+1) && listdata.length > 0">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>From Date: </td>
                        <td>{{$nova.formatDate(modifydata.from)}}</td>
                      </tr>
                      <tr>
                        <td>To Date: </td>
                        <td>{{$nova.formatDate(modifydata.to)}}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div v-else>
                  <v-alert type="info" :value="true" text border="left">
                    No details available 
                  </v-alert>
                </div>
              </div>
              
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import pageHeader from '@/components/pageHeader'
// import managelist from '@/components/ManageList'

export default {
  name: 'engagement_list',
  // props: ['props'],
  data: function(){
    return {
      pageprops: {
        name: "Engagement",
        breadcrumbs: [],
      },
      listdata: [],
      addflag: false,
      editflag: false,
      selectedindex: 0,
      modifydata: {},
      errors: {},
      selectdata: {
        "type": ["Internal Audit"],
        "client": []
      }
    }
  },
  // components: {
  //   // pageHeader,managelist
  // },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      if(this.$store.state.client !== ''){
        this.errors = {};
        this.modifydata = {};
        this.addflag = false;
        this.editflag = false;
        this.getData();
      }
    },
    getData() {
      this.axios.post("/v2/engagement/clientlist").then(dt => {
        this.selectdata.client = [];
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.selectdata.client = dt.data.data;
        }
        return this.axios.post("/v2/engagement/get");
      }).then(dt => {
        if(dt.data.status === "success"){
          this.listdata = dt.data.data;
        }else{
          console.log("Error fetching data");
        }
      })
    },
    save(){
      this.modifydata.type = "Internal Audit";
      this.axios.post("/v2/engagement/add", {data: [this.modifydata]}).then(dt => {
        if(dt.data.status === "success"){
          this.refreshData();
          this.$store.commit("refreshClient", true);
        }else{
          this.errors = {};
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
          }
          console.log("Error adding client");
        }
      })
    },
    update(){
      if(this.modifydata._id){
        this.axios.post("/v2/engagement/edit", {data: [this.modifydata], id: this.modifydata._id}).then(dt => {
          if(dt.data.status === "success"){
            this.refreshData();
            this.$store.commit("refreshClient", true);
          }else{
            this.errors = {};
            if (dt.data.data.length > 0){
              if(dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            console.log("Error updating client");
          }
        })
      }else console.log("No ID found to update");
    },
    clientName: function(d){
      let n = "";

      for (const i of this.selectdata.client) {
        if(i._id === d){
          n = i.name;
          break;
        }
      }
      return n;
    }
  },
  computed: {
  },
  watch: {
    "$store.state.client": function(){
      this.selectedindex = -1;
      this.modifydata = {};
      this.addflag = false;
      this.editflag = false;
      this.refreshData();
    }
  }
}
</script>
